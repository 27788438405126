import React, { useEffect } from 'react';

const GoogleAnalytics = ({ measurementId }) => {
    useEffect(() => {
        // Load Google Analytics
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
        script.async = true;
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                // eslint-disable-next-line no-undef
                dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', measurementId);
        };

        // Add gtag to window object
        if (!window.gtag) {
            window.gtag = function () {
                window.dataLayer.push(arguments);
            };
        }

        return () => {
            document.head.removeChild(script);
        };
    }, [measurementId]);

    return null;
};

export default GoogleAnalytics;