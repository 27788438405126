import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual } from 'swiper/modules';
import 'swiper/css';

const ProductSlider2 = () => {
    const imageNames = [
        '1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg',
        '6.jpg', '7.jpg', '8.jpg', '9.jpg', '10.jpg', '11.jpg'
    ];

    useEffect(() => {
        if (!window.preloadedImages) {
            window.preloadedImages = new Set();
        }

        imageNames.forEach(imageName => {
            const imagePath = `/images/content/${imageName}`;
            if (!window.preloadedImages.has(imagePath)) {
                const img = new Image();
                img.src = imagePath;
                window.preloadedImages.add(imagePath);
            }
        });
    }, []);

    return (
        <div className="max-w-full overflow-hidden">
            <h2 className="text-center text-xl font-bold tracking-wider mb-8">
                SWIPE THROUGH FILES
            </h2>
            <div className="h-[640px]"> 
                <Swiper
                    modules={[Virtual]}
                    spaceBetween={4}
                    slidesPerView={1.5}
                    breakpoints={{
                        0: {
                            slidesPerView: 1.2,
                        },
                        768: {
                            slidesPerView: 1.5,
                        },
                    }}
                    virtual
                    className="w-full h-full" 
                >
                    {imageNames.map((imageName, index) => (
                        <SwiperSlide key={index} virtualIndex={index} className="h-full"> 
                            <div className="flex justify-center items-center h-full">
                                <img
                                    src={`/images/content/${imageName}`}
                                    alt={`Review ${index + 1}`}
                                    className="max-h-full max-w-full object-contain"
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default ProductSlider2;