import React, { useState, useEffect } from 'react';

const FAQ = () => {
    const [openIndex, setOpenIndex] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const toggleAnswer = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const sections = [
        {
            question: "What's included in the trading course package?",
            answer: "Our comprehensive package includes: A complete e-book guide on trading, a structured roadmap for optimal learning, a ready-to-use trading plan template, over 240 in-depth video lessons (350+ hours of content), high-definition video quality (720p or 1080p), and lifetime access to all materials and future updates."
        },
        {
            question: "How can I pay for the course?",
            answer: "We offer secure payment options: Credit/Debit cards (Visa and Mastercard) and cryptocurrency payments (USDT) upon request. All payments are one-time only, with no recurring charges."
        },
        {
            question: "Is this course suitable for beginners?",
            answer: "Absolutely! This course is designed to take you from the basics to advanced trading concepts. It's perfect for beginners as it covers all aspects of trading, allowing you to find what resonates best with your style."
        },
        {
            question: "I'm an experienced trader. Will I benefit from this course?",
            answer: "Experienced traders can gain valuable insights from our course. It offers new perspectives from professionals, helping you refine your strategy and deepen your understanding of market dynamics."
        },
        {
            question: "What trading assets does this course cover?",
            answer: "The course covers a range of concepts that can be applied in trading assets like Forex, Stocks, Cryptocurrencies, and Indices. The principles taught can be applied across various markets."
        },
        {
            question: "How long do I have access to the course materials?",
            answer: "You receive lifetime access to all course materials, including any future updates we make to the content."
        },
        {
            question: "Are there any download limits?",
            answer: "While there are no strict limits, we recommend saving the files locally. Download links may be periodically updated to prevent unauthorized sharing."
        },
        {
            question: "Will this course guarantee my profitability in trading?",
            answer: "While we provide comprehensive knowledge and strategies, profitability in trading depends on various factors including your dedication, practice, and market conditions. Our course equips you with the tools and knowledge to make informed trading decisions, but success ultimately depends on how you apply what you learn."
        },
        {
            question: "What makes this course different from others?",
            answer: "Multiple professional perspectives on market liquidity, in-depth coverage of risk management and capital preservation, psychological and mindset training for traders, and a holistic approach covering theory, practice, and real-world application."
        },
        {
            question: "How quickly can I start trading after taking this course?",
            answer: "The course is designed to get you started quickly, but we recommend thoroughly understanding the concepts and practicing with a demo account before trading with real money. The time it takes to become proficient varies for each individual."
        },
        {
            question: "Do you offer any support or community access with the course?",
            answer: "The course includes access to a wealth of resources. While we don't provide direct mentoring, the comprehensive nature of our materials is designed to answer most questions you might have as you progress."
        },
        {
            question: "Can I access the course on mobile devices?",
            answer: "Yes, the course materials are accessible on various devices. However, for the best experience with high-definition videos, we recommend using a desktop or laptop computer."
        },
        {
            question: "How often is the course content updated?",
            answer: "We ensure content remains relevant to current market conditions and trading practices. Your lifetime access ensures you'll always have the most up-to-date information."
        }
    ];

    return (
        <div className="max-w-3xl mx-auto p-6 pt-40">
            <h1 className="text-xl font-bold mb-6">Frequently Asked Questions</h1>
            {sections.map((section, index) => (
                <div key={index} className="mb-4 border-b border-gray-200 pb-4">
                    <button
                        className="flex justify-between items-center w-full text-left"
                        onClick={() => toggleAnswer(index)}
                    >
                        <h2 className="text-lg font-semibold">{section.question}</h2>
                        <span className="text-xl">{openIndex === index ? '−' : '+'}</span>
                    </button>
                    {openIndex === index && (
                        <p className="text-gray-700 text-sm mt-2">{section.answer}</p>
                    )}
                </div>
            ))}
          
            <p className="mt-6 text-xs text-gray-600">
                For any other questions reach us by live chat or at contact@learnhowto.trade
            </p>
        </div>
    );
};

export default FAQ;