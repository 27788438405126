import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const StickyHeader = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className="fixed top-0 left-0 right-0 z-50 transition-all duration-300 ease-in-out">
            {!isScrolled && (
                <div
                    className="h-[30px] flex items-center justify-center text-black text-xs font-semibold"
                    style={{
                        background: 'linear-gradient(to right, #97b017, #c5e61e)',
                    }}
                >
                    Time is now 🔥
                </div>
            )}
            <div className={`bg-black transition-all duration-300 ease-in-out ${isScrolled ? 'py-2' : 'py-4'}`}>
                <div className="container mx-auto px-4 flex justify-center items-center">
                    <Link to="/">
                        <img
                            src={isScrolled ? "/images/logowhite.png" : "/images/logowhite.png"}
                            alt="Logo"
                            className="h-14 transition-all duration-300 ease-in-out"
                        />
                    </Link>
                </div>
            </div>
        </header>
    );
};

export default StickyHeader;