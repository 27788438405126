import { useState, useEffect, useCallback } from 'react';

const useTawk = () => {
    const [isTawkVisible, setIsTawkVisible] = useState(false);

    useEffect(() => {
        // Load Tawk.to script only once
        if (!document.getElementById('tawk-script')) {
            var s1 = document.createElement("script");
            s1.async = true;
            s1.src = 'https://embed.tawk.to/66c9dc2050c10f7a009ffc7f/1i6287odb';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s1.id = 'tawk-script';

            s1.onload = () => {
                // Ensure Tawk.to is hidden by default when it loads
                if (window.Tawk_API) {
                    window.Tawk_API.onLoad = function () {
                        window.Tawk_API.hideWidget();
                        setIsTawkVisible(false);
                    };
                }
            };

            document.body.appendChild(s1);
        }

        // Additional step to hide the widget if it's already loaded
        const hideWidgetOnLoad = setInterval(() => {
            if (window.Tawk_API && window.Tawk_API.hideWidget) {
                window.Tawk_API.hideWidget();
                setIsTawkVisible(false);
                clearInterval(hideWidgetOnLoad);
            }
        }, 100);

        // Cleanup function
        return () => {
            clearInterval(hideWidgetOnLoad);
        };
    }, []);

    const toggleTawk = useCallback(() => {
        if (window.Tawk_API) {
            if (isTawkVisible) {
                window.Tawk_API.hideWidget();
                setIsTawkVisible(false);
            } else {
                window.Tawk_API.showWidget();
                setIsTawkVisible(true);
            }
        } else {
            console.error('Tawk_API is not available');
        }
    }, [isTawkVisible]);

    return { toggleTawk, isTawkVisible };
};

export default useTawk;