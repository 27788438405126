import React, { useState } from 'react';
import { FaCopy } from 'react-icons/fa';


const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
const YANDEX_LINK = process.env.REACT_APP_YANDEX_LINK;
const DISCORD_LINK = process.env.REACT_APP_DISCORD_LINK;

const Access = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [copySuccess, setCopySuccess] = useState({ course: '', discord: '' });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const response = await fetch(`${API_DOMAIN}/verify-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password }),
            });
            const data = await response.json();
            if (data.success) {
                setIsAuthenticated(true);
            } else {
                setError('Incorrect password. Please try again.');
            }
        } catch (error) {
            setError('An error occurred. Please try again.');
            console.error('Authentication error:', error);
        }

        setIsLoading(false);
    };

    const copyToClipboard = (text, type) => {
        navigator.clipboard.writeText(text).then(() => {
            setCopySuccess(prev => ({ ...prev, [type]: 'Copied!' }));
            setTimeout(() => setCopySuccess(prev => ({ ...prev, [type]: '' })), 2000);
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };

    if (!isAuthenticated) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white p-4">
                <div className="w-full max-w-md">
                    <form onSubmit={handleSubmit} className="bg-black shadow-md rounded px-8 py-6 mb-4">
                        <div className="flex flex-col md:flex-row md:items-end md:space-x-4">
                            <div className="flex-grow mb-4 md:mb-0">
                               
                                <input
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    id="password"
                                    type="password"
                                    placeholder="Enter password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className="flex-shrink-0">
                                <button
                                    className={`w-full bg-[#c5e61e] hover:bg-[#d4f720] text-black font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Verifying...' : 'Access'}
                                </button>
                            </div>
                        </div>
                        {error && <p className="text-red-500 text-xs italic mt-4">{error}</p>}
                    </form>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white p-4">
            <div className="w-full max-w-4xl">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    <div className="flex flex-col items-center space-y-4">
                        <a href={YANDEX_LINK} target="_blank" rel="noopener noreferrer" className="w-full">
                            <button className="bg-[#c5e61e] text-black text-xs font-bold py-3 px-4 rounded w-full hover:bg-[#d4f720] transition duration-300">
                                DOWNLOAD COURSE
                            </button>
                        </a>
                        <div className="flex items-center justify-center w-full">
                            <button
                                onClick={() => copyToClipboard(YANDEX_LINK, 'course')}
                                className="flex items-center justify-center text-[#c5e61e] hover:text-[#d4f720] transition duration-300"
                            >
                                <FaCopy className="mr-2" />
                                Copy Link
                            </button>
                            {copySuccess.course && <span className="ml-2 text-white-500">{copySuccess.course}</span>}
                        </div>
                    </div>
                    <div className="flex flex-col items-center space-y-4">
                        <a href={DISCORD_LINK} target="_blank" rel="noopener noreferrer" className="w-full">
                            <button className="bg-[#c5e61e] text-black text-xs font-bold py-3 px-4 rounded w-full hover:bg-[#d4f720] transition duration-300">
                                DISCORD INVITE
                            </button>
                        </a>
                        <div className="flex items-center justify-center w-full">
                            <button
                                onClick={() => copyToClipboard(DISCORD_LINK, 'discord')}
                                className="flex items-center justify-center text-[#c5e61e] hover:text-[#d4f720] transition duration-300"
                            >
                                <FaCopy className="mr-2" />
                                Copy Link
                            </button>
                            {copySuccess.discord && <span className="ml-2 text-white-500">{copySuccess.discord}</span>}
                        </div>
                    </div>
                </div>
            </div>
            <p className="mt-12 text-sm text-center text-gray-400">
                For technical issues, reach technical@learnhowto.trade
            </p>
        </div>
    );
};

export default Access;