import React from 'react';
import { Link } from 'react-router-dom';

const FeaturedProduct = () => {
    return (
        <div className="bg-white py-16 ">
            <div className="container mx-auto px-4">
                <header className="text-center mb-12">
                    <h3 className="text-black text-sm font-semibold uppercase mb-2 tracking-widest">FOLLOW THE PRICE INTENTION</h3>
                    <h2 className="text-black text-4xl font-semibold tracking-widest ">MOVE WITH THE BANKS</h2>
                </header>
                <div className="space-y-12 flex flex-col items-center">
                    {/* Featured Product Section */}
                    <div className="flex flex-col md:flex-row items-center md:items-start max-w-4xl w-full mt-6">
                        <div className="flex-shrink-0 mb-6 md:mb-0 md:mr-6">
                            <Link to="/njat-trading-course">
                                <img
                                    src="images/productimage.webp"
                                    alt="Featured Product"
                                    style={{ width: '400px', height: '350px', objectFit: 'cover' }}
                                    className="shadow-lg"
                                />
                            </Link>
                        </div>
                        <div className="text-center md:text-left w-full">
                            <h3 className="text-gray-600 text-xs mb-4 mt-2 tracking-widest">LEARN HOW TO TRADE</h3>
                            <Link to="/njat-trading-course" className="block mb-1 no-underline">
                                <h2 className="text-black font-[450] text-xl tracking-widest">NJAT TRADING COURSE 2024</h2>
                            </Link>
                            <h2 className="text-black text-sm mb-4 tracking-widest italic">*Discord Included</h2>
                            <div className="mb-8">
                                <span className="text-lg font-[450] text-[#d40804] mr-6">£89</span>
                                <span className="text-lg text-gray-500 line-through opacity-50">£1000</span>
                            </div>
                            <Link to="/njat-trading-course" className="block w-full">
                                <button className="relative overflow-hidden bg-black text-white border border-gray-400 hover:text-black tracking-widest text-sm px-6 py-3 font-[450] transition duration-300 flex items-center justify-center w-full group">
                                    <span className="relative z-10">BUY IT NOW</span>
                                    <div className="absolute inset-0 bg-white transform -translate-x-full group-hover:translate-x-0 transition-transform duration-300"></div>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeaturedProduct;