import React from 'react';
import styled, { keyframes } from 'styled-components';

const scroll = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
`;

const MarqueeContainer = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  background: white;
`;

const MarqueeContent = styled.div`
  display: flex;
  animation: ${scroll} 20s linear infinite;
  &:hover {
    animation-play-state: paused;
  }
`;

const MarqueeItem = styled.div`
  flex-shrink: 0;
  padding: 2px 7%;
  padding-right: 300px; // Default padding for larger screens
  display: inline-block;

  @media (max-width: 768px) {
    padding-right: 100px; // Reduced padding for mobile screens
  }
`;

const images = [
  { src: "images/tradingview.png", alt: "TradingView" },
    { src: "images/ic.png", alt: "IC Markets" },
    { src: "images/ftmo.png", alt: "FTMO" }
  
];

const MarqueeBar = () => {
  return (
    <MarqueeContainer className="hura-marquee-bar hura-messages">
      <MarqueeContent>
        {images.map((image, index) => (
          <MarqueeItem key={index} className="hura-message">
            <img src={image.src} alt={image.alt} width="200px" />
          </MarqueeItem>
        ))}
      </MarqueeContent>
    </MarqueeContainer>
  );
};

export default MarqueeBar;