import React, { useState, useEffect } from 'react';

const SalesPopup = () => {
    const [sale, setSale] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const names = [
        "John", "Jane", "Alex", "Emily", "Michael", "Olivia", "William", "Sophia",
        "Jacob", "Emma", "Maria", "Ahmed", "Ana", "Daniel", "Fatima", "Luca",
        "Aisha", "Pedro", "Sofia", "Samuel", "Elena", "Mohamed", "Isabella",
        "Leandro", "Alice", "Kwame", "Marta", "Diego", "Gabriela", "Amir",
        "Lucia", "Carlos", "Amina", "Nia", "Enzo", "Julia", "Raphael", "Ines", "Adrian"
    ];

    const cities = [
        "London, UK", "New York, US", "Tokyo, Japan", "Paris, France", "Berlin, Germany",
        "Sydney, Australia", "Toronto, Canada", "Rome, Italy", "Barcelona, Spain",
        "Moscow, Russia", "Lisbon, Portugal", "Cairo, Egypt", "Dublin, Ireland",
        "Amsterdam, Netherlands", "Rio de Janeiro, Brazil", "Mumbai, India",
        "Cape Town, South Africa", "Stockholm, Sweden", "Seoul, South Korea",
        "Bangkok, Thailand", "Athens, Greece", "Mexico City, Mexico", "Helsinki, Finland",
        "Oslo, Norway", "Warsaw, Poland", "Buenos Aires, Argentina", "Beijing, China",
        "Dubai, UAE", "Auckland, New Zealand"
    ];

    const getRandomIndex = (max) => Math.floor(Math.random() * max);

    const getRandomName = () => names[getRandomIndex(names.length)];
    const getRandomCity = () => cities[getRandomIndex(cities.length)];

    const generateSalesEntries = (count) => {
        return Array.from({ length: count }, (_, i) => ({
            productName: i % 2 === 0 ? "NJAT TRADING COURSE 2024" : "NJAT TRADING COURSE 2024",
            customerName: getRandomName(),
            location: getRandomCity()
        }));
    };

    const salesData = generateSalesEntries(50);

    const getRandomSale = () => salesData[getRandomIndex(salesData.length)];

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newSale = getRandomSale();
            setSale(newSale);
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 2000);
        }, 60000);

        return () => clearInterval(intervalId);
    }, []);

    if (!showPopup || !sale) return null;

    return (
        <div style={{
            position: 'fixed',
            top: '20px',
            left: '20px',
            padding: '10px',
            background: '#000',
            color: '#fff',
            border: '0px #181818',
            boxShadow: '0 1px 100px #ffffff45',
            zIndex: 9999,
            lineHeight: 1.1
        }}>
            <p>{sale.customerName} from {sale.location}</p>
            <p>purchased <strong>{sale.productName}</strong></p>
        </div>
    );
};

export default SalesPopup;