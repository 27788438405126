import React, { useEffect } from 'react';

const TermsAndConditions = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const sections = [
        {
            title: "1. Acceptance of Terms",
            content: "By accessing and using our website or purchasing products/services, you agree to comply with these Terms and Conditions."
        },
        {
            title: "2. User Responsibilities",
            content: "Users are responsible for providing accurate information during the purchase process, maintaining the confidentiality of their account information, and complying with applicable laws."
        },
        {
            title: "3. Intellectual Property",
            content: "All content on our website, including trademarks, copyrights, and any other intellectual property, is owned by us. Users are not allowed to use, reproduce, or distribute our content without explicit permission."
        },
        {
            title: "4. Limitations of Liability",
            content: "We limit our liability in case of any damages or losses incurred by users while using our website or product. We are not responsible for any consequential, indirect, or incidental damages."
        },
        {
            title: "5. No Refunds Policy",
            content: "Due to the nature of the Product being digital and downloadable, we do not offer refunds once the purchase is complete. By purchasing the Product, you acknowledge and agree to this no-refund policy."
        },
        {
            title: "6. Dispute Resolution",
            content: "Any disputes will be resolved through arbitration or mediation. Legal actions will be subject to the jurisdiction specified in these terms."
        },
        {
            title: "7. Modifications to Terms",
            content: "We reserve the right to modify or update these Terms and Conditions at any time. Users are responsible for reviewing the terms periodically."
        },
        {
            title: "8. Termination of Services",
            content: "We reserve the right to terminate or suspend user accounts under certain circumstances, as specified in these terms."
        },
        {
            title: "9. Governing Law",
            content: "These Terms and Conditions are governed by the laws of [Your Jurisdiction]. Any legal action related to these terms will be subject to the exclusive jurisdiction of the courts."
        }
    ];

    return (
        <div className="max-w-3xl mx-auto p-6 pt-40"> {/* Added pt-24 for top padding */}
            <h1 className="text-xl font-bold mb-6">TERMS & CONDITIONS</h1>
            {sections.map((section, index) => (
                <section key={index} className="mb-6">
                    <h2 className="text-lg font-semibold mb-2">{section.title}</h2>
                    <p className="text-gray-700 text-sm">{section.content}</p>
                </section>
            ))}
            <hr className="my-6 border-gray-200" /> {/* Divider before final paragraphs */}
            <p className="mt-6 text-xs text-gray-600">
                These Terms and Conditions constitute a legally binding agreement between you and "Learn How to TRADE". By using our website or purchasing our products, you acknowledge that you have read, understood, and agree to be bound by these terms.
            </p>
            <p className="mt-4 text-xs text-gray-600">
                Please note: While we have made every effort to ensure the accuracy and completeness of these Terms and Conditions, we recommend consulting with legal professionals to ensure compliance with relevant laws and to tailor these terms to your specific business needs.
            </p>
        </div>
    );
};

export default TermsAndConditions;