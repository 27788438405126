import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faShieldAlt, faUserShield, faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

const CheckoutForm = ({ onDiscountRemoved }) => {
    const [email, setEmail] = useState('');
    const [discountCode, setDiscountCode] = useState('');
    const [discountAmount, setDiscountAmount] = useState(0);
    const [error, setError] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('idle');
    const [isDiscountApplied, setIsDiscountApplied] = useState(false);
    const [paymentRequest, setPaymentRequest] = useState(null);
    const [isApplyingDiscount, setIsApplyingDiscount] = useState(false);
    const [showFastPayment, setShowFastPayment] = useState(true);
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (stripe) {
            const initializePaymentRequest = () => {
                const pr = stripe.paymentRequest({
                    country: 'GB',
                    currency: 'gbp',
                    total: {
                        label: 'Video Course',
                        amount: (89 - discountAmount) * 100, // Amount in cents
                    },
                    requestPayerEmail: true,
                    requestPayerName: true,
                });

                pr.canMakePayment().then(result => {
                    if (result) {
                        setPaymentRequest(pr);
                    }
                });

                pr.on('paymentmethod', handlePaymentMethod);
            };

            initializePaymentRequest();
        }
    }, [stripe, discountAmount]);

    const handlePaymentMethod = async (e) => {
        setPaymentStatus('processing');
        try {
            const { clientSecret } = await createPaymentIntent(e.payerEmail, discountCode);
            const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
                clientSecret,
                { payment_method: e.paymentMethod.id },
                { handleActions: false }
            );

            if (confirmError) {
                setError(confirmError.message);
                setPaymentStatus('idle');
                e.complete('fail');
            } else {
                e.complete('success');
                if (paymentIntent.status === 'requires_action') {
                    const { error } = await stripe.confirmCardPayment(clientSecret);
                    if (error) {
                        setError(error.message);
                        setPaymentStatus('idle');
                    } else {
                        await handlePaymentSuccess(paymentIntent.id, e.payerEmail);
                        setPaymentStatus('success');
                    }
                } else {
                    await handlePaymentSuccess(paymentIntent.id, e.payerEmail);
                    setPaymentStatus('success');
                }
            }
        } catch (error) {
            setError(error.message || 'An error occurred. Please try again.');
            setPaymentStatus('idle');
            e.complete('fail');
        }
    };

    const handleDiscountApply = async () => {
        setIsApplyingDiscount(true);
        try {
            const response = await fetch(`${API_DOMAIN}/apply-discount`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ discountCode }),
            });
            const data = await response.json();
            if (data.success) {
                setDiscountAmount(data.discountAmount);
                setError(null);
                setIsDiscountApplied(true);
                setShowFastPayment(false);  // Hide fast payment when discount is applied

                if (paymentRequest) {
                    paymentRequest.update({
                        total: {
                            label: 'Video Course',
                            amount: (89 - data.discountAmount) * 100,
                        },
                    });
                }
            } else {
                setError(data.message);
                setDiscountAmount(0);
                setIsDiscountApplied(false);
                setShowFastPayment(true);  // Show fast payment if discount is not applied
            }
        } catch (error) {
            setError('Error applying discount. Please try again.');
            setDiscountAmount(0);
            setIsDiscountApplied(false);
            setShowFastPayment(true);  // Show fast payment if there's an error
        } finally {
            setIsApplyingDiscount(false);
        }
    };

    const handleRemoveDiscount = () => {
        setDiscountCode('');
        setDiscountAmount(0);
        setIsDiscountApplied(false);
        setError(null);
        setShowFastPayment(true);  // Show fast payment when discount is removed

        if (paymentRequest) {
            paymentRequest.update({
                total: {
                    label: 'Video Course',
                    amount: 8900,
                },
            });
        }

        // Call the callback function to notify the parent component
        onDiscountRemoved();
    };





    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setPaymentStatus('processing');

        if (!stripe || !elements) {
            setPaymentStatus('idle');
            return;
        }

        try {
            const { clientSecret } = await createPaymentIntent(email, discountCode);
            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: { email },
                },
            });

            if (result.error) {
                throw result.error;
            } else {
                await handlePaymentSuccess(result.paymentIntent.id, email);
                setPaymentStatus('success');
            }
        } catch (error) {
            console.error('Error:', error);
            setError(error.message || 'An error occurred. Please try again.');
            setPaymentStatus('idle');
        }
    };

    const createPaymentIntent = async (email, discountCode) => {
        const response = await fetch(`${API_DOMAIN}/create-payment-intent`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, discountCode }),
        });

        if (!response.ok) {
            throw new Error('Failed to create payment intent');
        }

        return response.json();
    };

    const handlePaymentSuccess = async (paymentIntentId, email) => {
        try {
            const processResponse = await fetch(`${API_DOMAIN}/process-payment`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    paymentIntentId,
                    email,
                }),
            });

            if (!processResponse.ok) {
                throw new Error('Failed to process payment on server');
            }

            const processResult = await processResponse.json();
            if (processResult.success) {
                const finalPrice = 89 - discountAmount;

                if (window.trackFacebookPurchase) {
                    window.trackFacebookPurchase(finalPrice);
                }

                elements.getElement(CardElement).clear();
            } else {
                throw new Error('Payment processed, but link failed to send');
            }
        } catch (error) {
            console.error('Error:', error);
            setError(error.message || 'An error occurred. Please try again.');
        }
    };

    const getButtonContent = () => {
        switch (paymentStatus) {
            case 'processing':
                return (
                    <>
                        <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                        PROCESSING...
                    </>
                );
            case 'success':
                return (
                    <>
                        <FontAwesomeIcon icon={faCheck} className="mr-2" />
                        PAYMENT SUCCESSFUL! CHECK YOUR EMAIL
                    </>
                );
            default:
                return (
                    <>
                        PAY {(89 - discountAmount).toFixed(2)}{'\u00A3'}
                        <span className="line-through ml-2">1000{'\u00A3'}</span>
                    </>
                );
        }
    };

    return (
        <form id="payment-form" onSubmit={handleSubmit} className="space-y-4">
            <div>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="EMAIL"
                    required
                    className="w-full px-3 py-2 text-black text-xs bg-white rounded border border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 outline-none"
                    disabled={paymentStatus === 'processing' || paymentStatus === 'success'}
                />
            </div>
            <div className="flex space-x-2">
                <input
                    type="text"
                    id="discountCode"
                    value={discountCode}
                    onChange={(e) => setDiscountCode(e.target.value)}
                    placeholder="DISCOUNT CODE"
                    className="flex-grow px-3 py-2 text-black text-xs bg-white rounded border border-gray-400 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 outline-none"
                    disabled={paymentStatus !== 'idle' || isDiscountApplied || isApplyingDiscount}
                    readOnly={isDiscountApplied}
                />
                {isDiscountApplied ? (
                    <div className="flex">
                        <button
                            type="button"
                            className="px-4 py-2 bg-[#c5e61e] text-black text-xxs font-[450] tracking-widest rounded-l transition duration-300"
                            disabled={true}
                        >
                            APPLIED -{'\u00A3'}{discountAmount.toFixed(2)}
                        </button>
                        <button
                            type="button"
                            onClick={handleRemoveDiscount}
                            className="px-2 py-2 bg-black text-white text-xs font-bold rounded-r hover:bg-red-600 transition duration-300"
                            disabled={paymentStatus !== 'idle'}
                        >
                            X
                        </button>
                    </div>
                ) : (
                    <button
                        type="button"
                        onClick={handleDiscountApply}
                        className="px-4 py-2 bg-black text-white text-xs font-[450] tracking-widest rounded hover:bg-gray-800 transition duration-300 flex items-center"
                        disabled={paymentStatus !== 'idle' || !discountCode.trim() || isApplyingDiscount}
                    >
                        {isApplyingDiscount ? (
                            <>
                                <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                                APPLYING...
                            </>
                        ) : (
                            'APPLY'
                        )}
                    </button>
                )}
            </div>
            <div id="card-element" className="p-3 bg-white rounded border border-gray-400">
                <CardElement
                    options={{
                        style: {
                            base: {
                                color: '#333333',
                                fontFamily: 'Arial, sans-serif',
                                fontSmoothing: 'antialiased',
                                fontSize: '14px',
                                '::placeholder': {
                                    color: '#aab7c4'
                                },
                                iconColor: '#333333'
                            },
                            invalid: {
                                color: '#e74c3c',
                                iconColor: '#e74c3c'
                            }
                        }
                    }}
                    disabled={paymentStatus !== 'idle'}
                />
            </div>
            <button
                type="submit"
                disabled={!stripe || paymentStatus !== 'idle'}
                className={`relative overflow-hidden ${paymentStatus === 'processing' ? 'bg-gray-500' :
                    paymentStatus === 'success' ? 'bg-[#c5e61e] text-black' : 'bg-black text-white'
                    } border border-gray-400 hover:text-black tracking-widest text-xs px-6 py-3 font-[450] transition duration-300 flex items-center justify-center w-full group`}
            >
                <span className="relative z-10">
                    {getButtonContent()}
                </span>
                <div className="absolute inset-0 bg-white transform -translate-x-full group-hover:translate-x-0 transition-transform duration-300"></div>
            </button>

            {paymentRequest && showFastPayment && (
                <div className="mb-4">
                    <div className="text-center text-xs text-gray-500 mb-4">Pay with card or 1-click delivery with</div>
                    <PaymentRequestButtonElement
                        options={{ paymentRequest }}
                        className="w-full"
                    />
                </div>
            )}

            {error && (
                <div id="card-errors" role="alert" className="text-red-500 text-sm">
                    {error}
                </div>
            )}
        </form>
    );
};

const PaymentForm = ({ onDiscountRemoved }) => {
    return (
        <div className="w-full">
            <Elements stripe={stripePromise}>
                <CheckoutForm onDiscountRemoved={onDiscountRemoved} />
            </Elements>
            {/* Security and Compliance Icons */}
            <div className="w-full mt-6 flex justify-center items-center space-x-6 text-gray-200">
                <div className="flex flex-col items-center">
                    <FontAwesomeIcon icon={faLock} size="xs" />
                    <span className="text-xs mt-1">Secure Payment</span>
                </div>
                <div className="flex flex-col items-center">
                    <FontAwesomeIcon icon={faShieldAlt} size="xs" />
                    <span className="text-xs mt-1">PCI Compliant</span>
                </div>
                <div className="flex flex-col items-center">
                    <FontAwesomeIcon icon={faUserShield} size="xs" />
                    <span className="text-xs mt-1">Privacy Protected</span>
                </div>
            </div>
        </div>
    );
};

export default PaymentForm;