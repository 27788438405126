import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

const ReviewsSlider1 = () => {
    const [reviewImages, setReviewImages] = useState([]);

    useEffect(() => {
        const imageNames = [
            '1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'
        ];

        // Shuffle the images
        const shuffledImages = [...imageNames].sort(() => 0.5 - Math.random());
        setReviewImages(shuffledImages);

       
    }, []);


    return (
        <div>
            <style>
                {`
                    @media (max-width: 767px) {
                        .review-image {
                            height: 350px !important;
                        }
                    }
                `}
            </style>
            <h2 style={{
                textAlign: 'center',
                margin: '40px 0 10px',
                fontSize: '14px',
                fontWeight: 'bold',
                letterSpacing: '0.1em'
            }}>
                A FOREX STRATEGY THAT WORKS
            </h2>
            <h2 style={{
                textAlign: 'center',
                margin: '10px 0',
                fontSize: '30px',
                fontWeight: 'bold',
                letterSpacing: '0.1em'
            }}>
                WHEN WINNING BECOMES EASY
            </h2>
            <Swiper
                spaceBetween={10}
                slidesPerView="auto"
                style={{ padding: '50px 10px 10px' }}
            >
                {reviewImages.map((imageName, index) => (
                    <SwiperSlide key={index} style={{ width: 'auto', height: 'auto' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                src={`/images/money/${imageName}`}
                                alt={`Review ${index + 1}`}
                                className="review-image"
                                style={{
                                    height: '500px',
                                    width: 'auto',
                                    objectFit: 'contain',
                                    borderRadius: '2px',
                                }}
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

export default ReviewsSlider1;