import React, { useEffect, useRef } from 'react';
import useIntersectionObserver from './utility/useIntersectionObserver';

const FeatureSection3 = () => {
    const hasPreloaded = useRef(false);
    const [ref, isIntersecting] = useIntersectionObserver({
        threshold: 0.1,
        triggerOnce: false, // Allow multiple triggers for the visual effect
        rootMargin: '200px'
    });

    useEffect(() => {
        if (isIntersecting && !hasPreloaded.current) {
            const preloadImages = () => {
                // ProductSlider1 images
                ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '1mobile.jpg'].forEach(imageName => {
                    const img = new Image();
                    img.src = `/images/product/${imageName}`;
                });

                // ProductSlider2 images
                ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '8.jpg', '9.jpg', '10.jpg', '11.jpg'].forEach(imageName => {
                    const img = new Image();
                    img.src = `/images/content/${imageName}`;
                });

                // ReviewsSlider2 images
                [
                    'Testimonials_all_new_0002_28.jpg', 'Testimonials_all_new_0008_22.jpg',
                    'Testimonials_all_new_0009_21.jpg', 'Testimonials_all_new_0011_19.jpg',
                    'Testimonials_all_new_0014_16.jpg', 'Testimonials_all_new_0015_15.jpg',
                    'Testimonials_all_new_0017_13.jpg', 'Testimonials_all_new_0018_12.jpg',
                    'Testimonials_all_new_0019_11.jpg', 'Testimonials_all_new_0022_08.jpg',
                    'Testimonials_all_new_0023_07.jpg', 'Testimonials_all_new_0027_03.jpg',
                    'Testimonials_all_new_0028_02.jpg', 'Testimonials_all_new_0029_01.jpg',
                    'Winners_new_0011_63.jpg', 'Winners_new_0063_11.jpg'
                ].forEach(imageName => {
                    const img = new Image();
                    img.src = `/images/reviews/${imageName}`;
                });


                // Product2 image (discord.gif)
                const discordImg = new Image();
                discordImg.src = '/images/product/discord.gif';

            };

            preloadImages();
            hasPreloaded.current = true;
            console.log('Preloading images started');
        }
    }, [isIntersecting]);

    return (
        <div
            ref={ref}
            style={{
                background: 'linear-gradient(35deg, #2b2e39 0%, #818387 40%, #2b2e39 70%)',
                padding: '4rem 0'  // This is equivalent to py-16
            }}
        >
            <div className="container mx-auto px-8">
                <div className="flex justify-center">
                    <div
                        className={`bg-white shadow-lg p-8 inline-block max-w-3xl relative ${isIntersecting ? 'animate-fadeIn' : 'opacity-0'
                            }`}
                    >
                        <div className="ImageHero__Block ImageHero__Block--large">
                            <h3 className="text-lg font-bold mb-6 text-black text-center">KNOW LIQUIDITY OR BE LIQUIDITY🧠</h3>
                            <div className="mb-6 overflow-hidden">
                                <img
                                    src="images/candles3.png"
                                    width="400"
                                    alt="Candles"
                                    className="mx-auto animate-zoomOutRevert"
                                />
                            </div>
                            <div className="ImageHero__BlockContent text-black">
                                <ul className="list-disc pl-6 space-y-2">
                                    <li><strong>Market is liquidity</strong>, highs and lows are created through orders and understanding this is the only way to truly master it</li>
                                    <li>The <strong>more perspectives</strong> about liquidity you know, the <strong>better you will trade</strong></li>
                                    <li>By knowing how <strong>professionals</strong> view it, you'll be able to <strong>craft your own</strong> recipe fitting your style and personality</li>
                                    <li>Learn all the <strong>manipulation traps</strong> and how to avoid them with ease</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureSection3;