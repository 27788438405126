import React, { useEffect } from 'react';
import useIntersectionObserver from './utility/useIntersectionObserver';

const BenefitItem = ({ children }) => (
    <li className="mb-2 text-white sm:whitespace-nowrap">
        {children}
    </li>
);

const FeatureSection2 = () => {
    const [ref, isIntersecting] = useIntersectionObserver({
        threshold: 0.1,
        triggerOnce: false
    });

    useEffect(() => {
        if (isIntersecting) {
            const imageNames = [
                '1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'
            ];

            if (!window.preloadedImages) {
                window.preloadedImages = new Set();
            }

            imageNames.forEach(imageName => {
                const imagePath = `/images/money/${imageName}`;
                if (!window.preloadedImages.has(imagePath)) {
                    const img = new Image();
                    img.src = imagePath;
                    window.preloadedImages.add(imagePath);
                }
            });
        }
    }, [isIntersecting]);

    return (
        <div
            ref={ref}
            style={{
                background: 'linear-gradient(-35deg, #2b2e39 0%, #818387 40%, #2b2e39 70%)',
                padding: '4rem 0'  // This is equivalent to py-16
            }}
        >
            <div className="container mx-auto px-8 flex justify-center">
                <div
                    className={`bg-black shadow-lg p-8 relative ${isIntersecting ? 'animate-fadeIn' : 'opacity-0'
                        }`}
                >
                    <h3 className="text-white text-lg sm:text-xl font-bold mb-4 sm:mb-6 text-center ">
                        BENEFITS OF TRADING AS A CAREER 🔥
                    </h3>
                    <ul className="list-disc pl-4 sm:pl-6 space-y-2 text-left text-sm sm:text-base">
                        <BenefitItem>Develop a <strong>resilient mindset</strong> for optimal <strong>decision-making</strong></BenefitItem>
                        <BenefitItem>Cultivate <strong>discipline</strong> and <strong>focus</strong> to perform at a consistently high level</BenefitItem>
                        <BenefitItem>Learn to <strong>control</strong> yourself in new ways and gain <strong>patience</strong></BenefitItem>
                        <BenefitItem>Trade <strong>on your terms</strong>, from anywhere with an internet connection</BenefitItem>
                        <BenefitItem>Attain <strong>financial independence</strong> and pursue any personal goals</BenefitItem>
                        <BenefitItem>Create a lasting legacy by <strong>building wealth</strong> for yourself and future generations</BenefitItem>
                        <BenefitItem>Understand <strong>how to risk</strong> and manage your <strong>future investments</strong></BenefitItem>
                        <BenefitItem>Connect with communities of like-minded individuals for <strong>high-value networking</strong></BenefitItem>
                        <BenefitItem>Develop <strong>self-awareness</strong> and observation skills</BenefitItem>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default FeatureSection2;