import React from 'react';

const TextSection2 = () => {
    return (
        <div className="bg-black py-16">
            <div className="container mx-auto px-4">
                <div className="max-w-2xl mx-auto text-center">
                    <div className="text-white"> {/* Changed text color to white */}
                        <p className="text-2xl font-bold mb-4">BE PART OF THE 1%.</p>
                        <p className="text-lg mb-4">
                            <strong>Improvement</strong> never stops. Spice up your strategy.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TextSection2;