import React from 'react';
import { blogPosts } from '../pages/blogPosts';

const SitemapGenerator = () => {
    const baseUrl = 'https://learnhowto.trade';

    const createUrlElement = (loc, changefreq, priority) => `
    <url>
      <loc>${baseUrl}${loc}</loc>
      <changefreq>${changefreq}</changefreq>
      <priority>${priority}</priority>
    </url>`;

    const staticPages = [
        { loc: '/', changefreq: 'weekly', priority: '1.0' },
        { loc: '/faq', changefreq: 'monthly', priority: '0.8' },
        { loc: '/blog', changefreq: 'weekly', priority: '0.9' },
        { loc: '/policy', changefreq: 'yearly', priority: '0.5' },
        { loc: '/terms', changefreq: 'yearly', priority: '0.5' },
        { loc: '/njat-trading-course', changefreq: 'monthly', priority: '0.8' },
        { loc: '/njat-discord', changefreq: 'monthly', priority: '0.8' },
    ];

    const createUrlFriendlyTitle = (title) => {
        return title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
    };

    const sitemapContent = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  ${staticPages.map(page => createUrlElement(page.loc, page.changefreq, page.priority)).join('')}
  ${blogPosts.map(post => createUrlElement(`/blog/${createUrlFriendlyTitle(post.title)}`, 'monthly', '0.7')).join('')}
</urlset>`;

    return (
        <pre style={{ display: 'none' }}>
            {sitemapContent}
        </pre>
    );
};

export default SitemapGenerator;