import React, { useState, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';


// Use environment variable for API domain
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;


const VideoSection = () => {
    const [email, setEmail] = useState('');
    const [isUnlocked, setIsUnlocked] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const unlockedEmail = localStorage.getItem('unlockedEmail');
        if (unlockedEmail) {
            setIsUnlocked(true);
            setEmail(unlockedEmail);
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsLoading(true);

        try {
            const response = await fetch(`${API_DOMAIN}/collect-email`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

            const data = await response.json();

            if (response.ok) {
                setIsUnlocked(true);
                localStorage.setItem('unlockedEmail', email);

                // Track successful video unlock
                ReactPixel.track('Lead', {
                    content_name: 'Free Demo Video Unlock',
                    content_category: 'Video',
                    status: 'success',
                });
            } else {
                setError(data.message || 'An error occurred. Please try again.');
            }
        } catch (error) {
            setError('Network error. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <div className="w-full mb-16 relative">
            <h2 className="text-center text-sm font-bold tracking-wider  mb-2.5">
                SELECT 1080p QUALITY
            </h2>
            <h2 className="text-center text-3xl font-bold tracking-wider my-2.5 mb-8">
                WATCH FREE DEMO
            </h2>
            <ul className="text-center text-xs pl-5 mb-4">
               
                <li><strong>*On mobile it may be displayed at a lower resolution until gets loaded or if connection is poor.</strong></li>
            </ul>
            <div className="relative" style={{ paddingTop: '56.47%' }}>
                <iframe
                    title="231 TUESDAY LIVE BURTON M15 STRATEGY"
                    className="absolute top-0 left-0 w-full h-full"
                    src="https://player.vimeo.com/video/910143697?badge=0&autopause=0&player_id=0&app_id=58479"
                    allow="autoplay; fullscreen; picture-in-picture"
                    frameBorder="0"
                ></iframe>
                {!isUnlocked && (
                    <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white p-4 sm:p-6 rounded-lg shadow-lg max-w-xs sm:max-w-sm w-full mx-4">
                            <h3 className="text-xs text-center sm:text-sm font-bold mb-3 sm:mb-4">UNLOCK FREE DEMO VIDEO</h3>
                            <form onSubmit={handleSubmit} className="space-y-3 sm:space-y-4">
                                <input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter your email"
                                    required
                                    className="w-full px-3 py-2 text-sm sm:text-base border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                <button
                                    type="submit"
                                    className="w-full bg-[#c5e61e] text-black py-2 px-4 rounded-md hover:bg-[#d4f720] transition duration-300 text-sm font-semibold"
                                >
                                    UNLOCK VIDEO
                                </button>
                            </form>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default VideoSection;