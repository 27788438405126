import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Hero from './components/Hero';
import StickyHeader from './components/StickyHeader';
import FeatureSection from './components/FeatureSection';
import FeatureSection2 from './components/FeatureSection2';
import FeatureSection3 from './components/FeatureSection3';
import TextSection from './components/TextSection';
import TextSection2 from './components/TextSection2';
import FeaturedProduct from './components/FeaturedProduct';
import FeaturedProduct2 from './components/FeaturedProduct2';
import MarqueeBar from './components/MarqueeBar';
import NewsletterSubscription from './components/NewsletterSubscription';
import Footer from './components/Footer';
import SalesPopup from './components/SalesPopup';
import ReviewsSlider1 from './components/ReviewsSlider1';
import FacebookPixel from './components/tracking/FacebookPixel';
import GoogleAnalytics from './components/tracking/GoogleAnalytics';
import Blog from './components/pages/Blog';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import FAQ from './components/pages/FAQ';
import TermsAndConditions from './components/pages/TermsAndConditions';
import Product from './components/pages/Product';
import Product2 from './components/pages/Product2';
import Access from './components/pages/Access'; 
import useTidio from './components/utility/useTidio';
import useDisableInteractions from './components/utility/useDisableInteractions';
import SitemapGenerator from './components/utility/SitemapGenerator';

function App() {
   // useTidio(); // Use the Tidio hook to load the chat widget
    useDisableInteractions();  // Use the new hook to disable right-click



    return (
        <Router>
            <div className="App flex flex-col min-h-screen">
                <FacebookPixel pixelId={process.env.REACT_APP_FACEBOOK_PIXEL_ID} />
                <GoogleAnalytics measurementId={process.env.REACT_APP_GA_MEASUREMENT_ID} />
                <SalesPopup />
                <StickyHeader />

                <main className="flex-grow">
                    <Routes>
                        <Route path="/sitemap.xml" element={<SitemapGenerator />} />
                        <Route path="/" element={
                            <>
                                <Hero
                                    line1="MASTER FINANCIAL MARKETS"
                                    line2="GET AN EDGE ON LIFE"
                                />
                                <FeatureSection />
                                <TextSection />
                                <FeatureSection2 />
                                <TextSection2 />
                                <FeatureSection3 />
                                <ReviewsSlider1 />
                                <FeaturedProduct />
                                <FeaturedProduct2 />
                                <MarqueeBar />
                                <NewsletterSubscription />
                            </>
                        } />
                       
                        <Route path="/faq" element={<FAQ />} />
                        <Route path="/blog/*" element={<Blog />} />
                        <Route path="/policy" element={<PrivacyPolicy />} />
                        <Route path="/terms" element={<TermsAndConditions />} />
                        <Route path="/njat-trading-course" element={<Product />} />
                        <Route path="/njat-discord" element={<Product2 />} />
                        <Route path="/7Fx9Q2hL3mRpY5nK8cA1" element={<Access />} /> {/* Add this line */}
                        <Route path="*" element={<Navigate replace to="/njat-trading-course" />} />
                       
                    </Routes>
                </main>

                <Footer />
            </div>

        </Router>
    );
}


export default App;