import React, { useState, useEffect, useRef } from 'react';
import useIntersectionObserver from './utility/useIntersectionObserver';

const Hero = ({ line1, line2 }) => {
    const [videoError, setVideoError] = useState(false);
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [animated, setAnimated] = useState(false);
    const videoRef = useRef(null);
    const videoSrc = "/images/hero.mp4";
    const fallbackImageSrc = "/images/fallback-image.jpg";
    const [ref, isIntersecting] = useIntersectionObserver({
        threshold: 0.1,
        triggerOnce: false
    });

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            const handleLoadedData = () => {
                setVideoLoaded(true);
                if (isIntersecting) {
                    video.play().catch(e => console.error("Error playing video:", e));
                }
            };
            video.addEventListener('loadeddata', handleLoadedData);
            return () => video.removeEventListener('loadeddata', handleLoadedData);
        }
    }, [isIntersecting]);

    useEffect(() => {
        if (isIntersecting) {
            setAnimated(false);
            setTimeout(() => setAnimated(true), 100);
            if (videoRef.current && videoLoaded && !videoError) {
                videoRef.current.play().catch(e => console.error("Error playing video:", e));
            }
        } else {
            setAnimated(false);
        }
    }, [isIntersecting, videoLoaded, videoError]);

    

    return (
        <div ref={ref} className="relative h-screen w-full overflow-hidden bg-gray-200 font-sans">
            <img
                src={fallbackImageSrc}
                alt="Fallback background"
                className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-300 ${videoLoaded && !videoError ? 'opacity-0' : 'opacity-100'}`}
            />
            {!videoError && (
                <video
                    ref={videoRef}
                    className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-300 ${videoLoaded ? 'opacity-100' : 'opacity-0'}`}
                    src={videoSrc}
                    autoPlay
                    loop
                    muted
                    playsInline
                    poster={fallbackImageSrc}
                    onError={() => setVideoError(true)}
                />
            )}
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>
            <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
                <h1 className={`text-xl md:text-4xl font-bold mb-4 text-center transition-all duration-1000 ease-out
                    ${animated ? 'opacity-100 blur-none scale-100' : 'opacity-0 blur-lg scale-150'}`}>
                    {line1}
                </h1>
                <p className={`text-5xl md:text-6xl font-bold text-center transition-all duration-1000 ease-out delay-300
                    ${animated ? 'opacity-100 blur-none scale-100' : 'opacity-0 blur-lg scale-150'}`}>
                    {line2}
                </p>
            </div>
        </div>
    );
};

export default Hero;