import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

const ProductSlider1 = () => {
    const imageNames = [
        '1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg'
    ];

    useEffect(() => {
        if (!window.preloadedImages) {
            window.preloadedImages = new Set();
        }

        imageNames.forEach(imageName => {
            const imagePath = `/images/product/${imageName}`;
            if (!window.preloadedImages.has(imagePath)) {
                const img = new Image();
                img.src = imagePath;
                window.preloadedImages.add(imagePath);
            }
        });

        // Preload mobile version
        const mobilePath = '/images/product/1mobile.jpg';
        if (!window.preloadedImages.has(mobilePath)) {
            const mobileImg = new Image();
            mobileImg.src = mobilePath;
            window.preloadedImages.add(mobilePath);
        }
    }, []);


    return (
        <div>
            <style>
                {`
                    @media (max-width: 767px) {
                      .review-image {
                max-height: 250px !important;
                height: auto !important;
            }
                        .swiper-pagination {
                            position: relative !important;
                            bottom: 0 !important;
                            margin-top: 10px;
                        }
                    }
                    .swiper-slide {
                        width: auto;
                        height: auto;
                    }
                    .swiper-pagination-bullet {
                        background: #000;
                        opacity: 0.2;
                    }
                    .swiper-pagination-bullet-active {
                        opacity: 1;
                    }
                `}
            </style>
            <h2 className="text-center mt-10 mb-2.5 text-sm font-bold tracking-widest">
                THE LAST COURSE YOU NEED
            </h2>
            <h2 className="text-center my-2.5 text-3xl font-bold tracking-widest">
                NJAT TRADING COURSE 2024
            </h2>
            <Swiper
                spaceBetween={10}
                slidesPerView="auto"
                className="pt-12 px-2.5"
                modules={[Pagination]}
                pagination={{
                    clickable: true,
                    el: '.swiper-pagination',
                }}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 'auto',
                        pagination: false,
                    },
                }}
            >
                {imageNames.map((imageName, index) => (
                    <SwiperSlide key={index}>
                        <div className="flex justify-center items-center">
                            <picture>
                                {index === 0 && (
                                    <source
                                        media="(max-width: 767px)"
                                        srcSet="/images/product/1mobile.jpg"
                                    />
                                )}
                                <img
                                    src={`/images/product/${imageName}`}
                                    alt={`Review ${index + 1}`}
                                    className="review-image h-[300px] w-auto object-contain rounded"
                                />
                            </picture>
                        </div>
                    </SwiperSlide>
                ))}
                <div className="swiper-pagination"></div>
            </Swiper>
        </div>
    );
};

export default ProductSlider1;