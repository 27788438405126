import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReviewsSlider3 from './ReviewsSlider3';
import ProductSlider1 from './ProductSlider1';
import ProductSlider2 from './ProductSlider2';
import VideoSection from './VideoSection';
import PaymentForm from './PaymentForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileVideo, faHourglassHalf, faFireAlt, faCreditCard, faDownload, faInfinity } from '@fortawesome/free-solid-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';

const StickyButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            const paymentForm = document.getElementById('payment-form');
            if (paymentForm) {
                const rect = paymentForm.getBoundingClientRect();
                setIsVisible(rect.bottom < 0 && window.innerWidth < 768);
            }
        };

        window.addEventListener('scroll', toggleVisibility);
        window.addEventListener('resize', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
            window.removeEventListener('resize', toggleVisibility);
        };
    }, []);

    const scrollToPaymentForm = () => {
        const paymentForm = document.getElementById('payment-form');
        if (paymentForm) {
            paymentForm.scrollIntoView({ behavior: 'smooth' });
        }
    };

    if (!isVisible) return null;

    return (
        <button
            onClick={scrollToPaymentForm}
            className="fixed bottom-0 left-0 w-full bg-[#c5e61e] text-black border-t border-gray-400 hover:text-[#c5e61e] tracking-widest text-sm px-6 py-3 font-[450] transition duration-300 flex items-center justify-center group overflow-hidden z-[9999]"
        >
            <span className="relative z-10">GET DOWNLOAD LINK</span>
            <div className="absolute inset-0 bg-black transform -translate-x-full group-hover:translate-x-0 transition-transform duration-300"></div>
        </button>
    );
};

const Product = () => {
    const [refreshKey, setRefreshKey] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleDiscountRemoved = () => {
        setRefreshKey(prevKey => prevKey + 1);
    };

    return (
          <>
            <Helmet>
                <title>NJAT Trading Course 2024 - RIMC Strategy 15m and 1m</title>
                <meta name="description" content="Get access to over 350 hours of content, 243 videos, Trading Plan, Roadmap and a lifetime Discord view-only. Learn trading strategies, market analysis, and risk management." />
                <meta name="keywords" content="njat, njat trading, not just a trade, notjustatrade ,trading course, RIMC, market analysis, risk management, trading strategies, forex strategy" />
                <meta property="og:title" content="RIMC Trading Course - Comprehensive Trading Education" />
                <meta property="og:description" content="Find your trading potential with our in-depth course featuring 350+ hours of content, 243 videos, roadmap, trading plan and more." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://learnhowto.trade/njat-trading-course" />
               
          
            </Helmet>
        <div className="flex flex-col items-center w-full max-w-6xl mx-auto px-4 mt-28">
            {/* Product Slider */}
            <div className="w-full mt-4">
                <ProductSlider1 />
            </div>
            {/* Two-column layout */}
            <div className="w-full mt-16 flex flex-col md:flex-row items-start justify-between">
                {/* Left column: Text and Payment */}
                <div className="w-full md:w-1/2 pr-0 md:pr-8">
                    {/* Product Description */}
                    <div className="w-full text-center mb-4">
                        <h2 className="text-xl font-bold mb-10">WHAT'S INCLUDED?</h2>
                        <ul className="space-y-3 text-gray-700 text-sm pl-5 text-left inline-block mx-auto">
                            {[
                                { icon: faFilePdf, text: <><strong>RIMC E-Book.</strong> Complete guide.</> },
                                { icon: faFilePdf, text: <><strong>Roadmap Guide Included.</strong> Watch order of the videos for best learning experience.</> },
                                { icon: faFilePdf, text: <><strong>Trading Plan Included.</strong> Ready to go example.</> },
                                { icon: faFileVideo, text: <><strong>Complete In-depth Trading Course (243 Videos).</strong> All content until August 2024.</> },
                                { icon: 'HD', text: <><strong>High Definition Quality.</strong> Make no concessions.</> },
                                { icon: faHourglassHalf, text: <><strong>Over 350 Hours of Content (132GB).</strong> Months of learning ahead.</> },
                                { icon: faFireAlt, text: <><strong>0.35£ per video or 0.25£ per hour.</strong> Insane pricer per value.</> },
                                { icon: faDiscord, text: <><strong>Lifetime Discord Included.</strong> View Only Access.</> },
                                { icon: faInfinity, text: <><strong>Lifetime Access.</strong> Get free any updates.</> },
                                { icon: faCreditCard, text: <><strong>One-time Payment.</strong> No subscription.</> },
                                { icon: faDownload, text: <><strong>Instant Download Link.</strong> Blazing fast access by email .</> },
                            ].map((item, index) => (
                                <li key={index} className="flex items-start">
                                    <div className="w-8 flex justify-center">
                                        {item.icon === 'HD' ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.2 19.2" width="19.2" height="19.2" className="inline-block">
                                                <rect width="19.2" height="19.2" rx="1.6" ry="1.6" fill="black" />
                                                <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontFamily="Arial, sans-serif" fontSize="11.2" fontWeight="bold" fill="white">HD</text>
                                            </svg>
                                        ) : (
                                            <FontAwesomeIcon icon={item.icon} className="text-black" />
                                        )}
                                    </div>
                                    <div className="flex-1 ml-2">{item.text}</div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* Payment Form */}
                    <div className="w-full mt-8">
                        <PaymentForm key={refreshKey} onDiscountRemoved={handleDiscountRemoved} />
                    </div>
                </div>
                {/* Right column: ProductSlider2 */}
                <div className="w-full md:w-1/2 mt-8 md:mt-0">
                    <ProductSlider2 />
                </div>
            </div>
            {/* Reviews Section */}
            <div className="w-full mt-6 mb-16">
                <ReviewsSlider3 />
            </div>

            {/* New Video Section */}
            <VideoSection />

            <StickyButton />
            </div>
        </>
    );
};

export default Product;