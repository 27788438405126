import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDownload,
    faHistory,
    faChartLine,
    faPeopleGroup,
    faToolbox,
    faBrain,
    faVideo,
    faCalendarWeek,
    faClock
} from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faYoutube } from '@fortawesome/free-brands-svg-icons';

const Product2 = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <>
            <Helmet>
                <title>RIMC Trading Course - Discord View-Only Access</title>
                <meta name="description" content="Get view-only access to our exclusive Discord community. Benefit from 1000+ professional markups, trading tools, and years of valuable content." />
                <meta name="keywords" content="njat, njat trading, not just a trade, notjustatrade, discord trading community, professional markups, trading tools, mindset content" />
                <meta property="og:title" content="RIMC Trading Course - Exclusive Discord Community" />
                <meta property="og:description" content="Join our exclusive Discord community with view-only access. Access 1000+ professional markups, trading tools, and years of valuable content." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://learnhowto.trade/njat-discord" />
            </Helmet>

            <div className="bg-white py-16">
                <div className="container mx-auto px-4 mt-20">
                    <h2 className="text-center text-sm font-bold tracking-wider mb-2.5">
                        DIVE DEEP WITH MARKUPS AND TOOLS
                    </h2>
                    <h2 className="text-center text-3xl font-bold tracking-wider mb-10">
                        DISCORD VIEW-ONLY ACCESS
                    </h2>

                    <div className="flex flex-col md:flex-row justify-between items-start md:space-x-8">
                        {/* Left column with image */}
                        <div className="w-full md:w-1/2 mb-8 md:mb-0 flex justify-center md:justify-end items-start">
                            <img src="/images/product/discord.gif" alt="Discord Community" className="w-full max-w-md rounded-lg shadow-lg" />
                        </div>

                        {/* Right column with features list */}
                        <div className="w-full md:w-1/2">
                            <div className="w-full text-left mb-4">
                                <ul className="space-y-3 text-gray-700 text-sm pl-5 text-left inline-block mx-auto">
                                    {[
                                        { icon: faDownload, text: "Instant Invite Link Download" },
                                        { icon: faDiscord, text: "View-Only Discord Access (No live updates)" },
                                        { icon: faHistory, text: "Full Channels History" },
                                        { icon: faChartLine, text: "1000+ Markups from Professionals" },
                                        { icon: faPeopleGroup, text: "10 000+ Markups from Community" },
                                        { icon: faToolbox, text: "Trading Tools" },
                                        { icon: faBrain, text: "Mindset & Psychology Content" },
                                        { icon: faYoutube, text: "Unlisted YouTube Videos" },
                                        { icon: faCalendarWeek, text: "Weekly Focus & Risk Management" },
                                        { icon: faClock, text: "3 Years+ of Content" },
                                    ].map((item, index) => (
                                        <li key={index} className="flex items-start">
                                            <div className="w-8 flex justify-center">
                                                <FontAwesomeIcon icon={item.icon} className="text-black" />
                                            </div>
                                            <div className="flex-1 ml-2">{item.text}</div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="text-center md:text-left mt-8">
                                <Link to="/njat-trading-course" className="inline-block">
                                    <button className="relative overflow-hidden bg-black text-xs text-white border border-gray-400 hover:text-black tracking-widest text-sm px-6 py-3 font-[450] transition duration-300 flex items-center justify-center group">
                                        <span className="relative z-10">INCLUDED IN TRADING COURSE</span>
                                        <div className="absolute inset-0 bg-white transform -translate-x-full group-hover:translate-x-0 transition-transform duration-300"></div>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Product2;