import React, { useState } from 'react';
import ReactPixel from 'react-facebook-pixel';



// Use environment variable for API domain
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;


const NewsletterSubscription = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setIsError(false);
        setIsLoading(true);

        try {
            const response = await fetch(`${API_DOMAIN}/collect-email-newsletter`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
            const data = await response.json();
            if (response.ok) {
                setMessage('Thank you for subscribing! Check your email for the access link.');
                setEmail('');

                // Track successful subscription
                ReactPixel.track('Lead', {
                    content_name: '8 Steps Emotional Management Guide',
                    content_category: 'Newsletter',
                    status: 'success',
                });
            } else {
                setIsError(true);
                setMessage(data.message || 'An error occurred. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setIsError(true);
            setMessage('An error occurred. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <section className="bg-[#c5e61e] py-16">
            <div className="container mx-auto px-4">
                <div className="text-center mb-8">
                    <h3 className="text-black text-sm font-bold uppercase mb-2">FREE GUIDE 🔥</h3>
                    <h2 className="text-black text-2xl font-semibold">8 STEPS EMOTIONAL MANAGEMENT</h2>
                </div>
                <form onSubmit={handleSubmit} className="max-w-md mx-auto">
                    <div className="flex">
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="flex-grow text-xs px-4 py-1 border border-gray-300 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-transparent"
                            aria-label="Enter your email"
                            placeholder="Enter your email"
                            required
                        />
                        <button
                            type="submit"
                            className="relative text-xs ml-6 overflow-hidden bg-black text-white border border-black hover:text-black tracking-widest text-sm px-4 py-3 font-[450] transition duration-300 flex items-center justify-center group whitespace-nowrap"
                            disabled={isLoading}
                        >
                            <span className="relative z-10">{isLoading ? 'SENDING...' : 'DOWNLOAD'}</span>
                            <div className="absolute inset-0 bg-white transform -translate-x-full group-hover:translate-x-0 transition-transform duration-300"></div>
                        </button>
                    </div>
                </form>
                {message && (
                    <div className={`mt-4 text-sm text-center ${isError ? 'text-red-500' : 'text-black-500'}`}>
                        {message}
                    </div>
                )}
            </div>
        </section>
    );
};

export default NewsletterSubscription;