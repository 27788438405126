import React, { useEffect } from 'react';

const PrivacyPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const sections = [
        {
            title: "1. Introduction",
            content: `Welcome to "Learn How to TRADE" (hereinafter referred to as "we," "us," or "our"). This Privacy Policy outlines our practices regarding the collection, use, and disclosure of personal information when you use our website at learnhowto.trade (the "Website") and purchase our digital product, access to a pack of videos to learn how to trade (the "Product"). We are not affiliated with any other parties. By accessing the Website and purchasing the Product, you agree to the terms and conditions of this Privacy Policy.`
        },
        {
            title: "2. Information We Collect",
            content: `We may collect personal information such as your name, email address, and payment details when you make a purchase on our Website. Additionally, we may collect non-personal information such as your IP address, browser type, and device information for analytical purposes.`
        },
        {
            title: "3. Use of Information",
            content: `We use the collected information for order processing, delivery of the Product, and to communicate with you about your purchase. We may also use non-personal information for website optimization and analytics.`
        },
        {
            title: "4. User-Provided Content",
            content: `Please note that the digital files associated with the Product are not hosted on our website (learnhowto.trade). Users provide the content through a third-party provider for file hosting. The use of your information by the third-party provider is subject to their privacy policy.`
        },
        {
            title: "5. Copyright",
            content: `If you believe that any material on our Website violates copyright infringement, please provide proof such as brand registration or evidence of the authorized person to act on behalf of the owner. Submit a written request to remove the respective material, and we will process it within 5 business days. Contact details for copyright-related inquiries: contact@learnhowto.trade.`
        },
        {
            title: "6. Changes to Privacy Policy",
            content: `We reserve the right to update or modify this Privacy Policy at any time without prior notice. Changes will be effective immediately upon posting on the Website. Please review this Privacy Policy periodically for any updates.`
        },
        {
            title: "7. Contact Us",
            content: `If you have any questions or concerns about this Privacy Policy, please contact us at contact@learnhowto.trade.`
        }
    ];

    return (
        <div className="max-w-3xl mx-auto p-6 pt-40"> {/* Added pt-24 for top padding */}
            <h1 className="text-xl font-bold mb-6">PRIVACY POLICY</h1>
            {sections.map((section, index) => (
                <section key={index} className="mb-6">
                    <h2 className="text-lg font-semibold mb-2">{section.title}</h2>
                    <p className="text-gray-700 text-sm">{section.content}</p>
                </section>
            ))}
            <hr className="my-6 border-gray-200" /> {/* Divider before final paragraphs */}
            <p className="mt-6 text-xs text-gray-600">
                Thank you for choosing "Learn How to TRADE." We are committed to safeguarding your privacy and providing you with the best learning experience.
            </p>
        </div>
    );
};

export default PrivacyPolicy;