import React from 'react';
import { Link } from 'react-router-dom';
import useTawk from './utility/useTawk';


const Footer = () => {
    const { toggleTawk, isTawkVisible } = useTawk();



    const handleEmailClick = (e) => {
        e.preventDefault();
        const username = 'info';
        const domain = 'learnhowto.trade';
        window.location.href = `mailto:${username}@${domain}`;
    };

    return (
        <footer className="bg-black text-white py-12">
            <div className="container mx-auto px-4">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {/* Navigation Links */}
                    <div>
                        <h3 className="text-sm font-semibold mb-4">Navigation</h3>
                        <ul className="space-y-1">
                            <li><Link to="/blog" className="hover:text-[#c5e61e] text-xs">Blog</Link></li>
                            <li><Link to="/faq" className="hover:text-[#c5e61e] text-xs">Frequently Asked Questions</Link></li>
                          
                            <li><Link to="/policy" className="hover:text-[#c5e61e] text-xs">Privacy Policy</Link></li>
                            <li><Link to="/terms" className="hover:text-[#c5e61e] text-xs">Terms & Conditions</Link></li>
                            <li>
                                <button
                                    className="hover:text-[#c5e61e] text-xs cursor-pointer bg-transparent border-none p-0 font-inherit"
                                    onClick={toggleTawk}
                                >
                                    {isTawkVisible ? 'Close Chat' : 'Live Chat'}
                                </button>
                            </li>
                            <li>
                                <a
                                    href="#"
                                    className="hover:text-[#c5e61e] text-xs"
                                    onClick={handleEmailClick}
                                >
                                    E-mail
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Social Icons */}
                    <div>
                        <h3 className="text-sm font-semibold mb-4">Socials</h3>
                        <div className="flex space-x-4">
                            <a href="https://www.instagram.com/learnhowto.trade/"
                                className="text-2xl hover:text-[#c5e61e]"
                                target="_blank"
                                rel="noopener noreferrer">
                                <i className="fab fa-instagram"></i>
                            </a>
                            <a href="https://www.youtube.com/@learnhowtotrade_official"
                                className="text-2xl hover:text-[#c5e61e]"
                                target="_blank"
                                rel="noopener noreferrer">
                                <i className="fab fa-youtube"></i>
                            </a>
                        </div>
                    </div>

                    {/* Payment Methods */}
                    <div>
                        <h3 className="text-sm font-semibold mb-4">Payment Methods</h3>
                        <div className="flex space-x-4">
                            <i className="fab fa-cc-visa text-2xl"></i>
                            <i className="fab fa-cc-mastercard text-2xl"></i>
                            <i className="fab fa-cc-apple-pay text-2xl"></i>
                            <i className="fab fa-cc-google-pay text-2xl"></i>
                        </div>
                    </div>
                </div>

                {/* Copyright */}
                <div className="mt-8 text-center text-xs text-gray-600">
                    <p>&copy; 2024 learn how to Trade. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;