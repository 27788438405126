import React from 'react';

const TextSection = () => {
    return (
        <div className="bg-black py-16">
            <div className="container mx-auto px-4">
                <div className="max-w-2xl mx-auto text-center">
                    <div className="text-[#c5e61e]"> {/* Changed text color to #c5e61e */}
                        <p className="text-2xl font-bold mb-4">BEARISH or BULLISH MARKET?</p>
                        <p className="text-lg mb-4">
                            Doesn't matter. We profit daily in the same way.{' '}
                            <em className="font-bold">
                                Protected from any recession or economical adversity.
                            </em>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TextSection;