import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Virtual, Pagination } from 'swiper/modules';
import 'swiper/css';

const ReviewsSlider3 = () => {
    const imageNames = [
        'Testimonials_all_new_0002_28.jpg',
        'Testimonials_all_new_0008_22.jpg',
        'Testimonials_all_new_0009_21.jpg',
        'Testimonials_all_new_0011_19.jpg',
        'Testimonials_all_new_0014_16.jpg',
        'Testimonials_all_new_0015_15.jpg',
        'Testimonials_all_new_0017_13.jpg',
        'Testimonials_all_new_0018_12.jpg',
        'Testimonials_all_new_0019_11.jpg',
        'Testimonials_all_new_0022_08.jpg',
        'Testimonials_all_new_0023_07.jpg',
        'Testimonials_all_new_0027_03.jpg',
        'Testimonials_all_new_0028_02.jpg',
        'Testimonials_all_new_0029_01.jpg',
        'Winners_new_0011_63.jpg',
        'Winners_new_0063_11.jpg'
    ];

    useEffect(() => {
        if (!window.preloadedImages) {
            window.preloadedImages = new Set();
        }

        imageNames.forEach(imageName => {
            const imagePath = `/images/reviews/${imageName}`;
            if (!window.preloadedImages.has(imagePath)) {
                const img = new Image();
                img.src = imagePath;
                window.preloadedImages.add(imagePath);
            }
        });
    }, []);

    return (
        <div className="max-w-full overflow-hidden">
            <style>
                {`
                    @media (max-width: 767px) {
                        .review-image {
                            max-height: 550px !important;
                            height: auto !important;
                        }
                        .swiper-pagination {
                            position: relative !important;
                            bottom: 0 !important;
                            margin-top: 10px;
                        }
                    }
                    .swiper-slide {
                        width: auto;
                        height: auto;
                    }
                    .swiper-pagination-bullet {
                        background: #000;
                        opacity: 0.2;
                    }
                    .swiper-pagination-bullet-active {
                        opacity: 1;
                    }
                `}
            </style>
            <h2 className="text-center text-sm font-bold tracking-wider mt-10 mb-2.5">
                IF THEY DID IT, YOU CAN DO IT
            </h2>
            <h2 className="text-center text-3xl font-bold tracking-wider my-2.5">
                WHEN WINNING BECOMES EASY
            </h2>
            <Swiper
                modules={[Virtual, Pagination]}
                spaceBetween={10}
                slidesPerView={2.4}
                className="pt-12 px-2.5"
                
                breakpoints={{
                    0: {
                        slidesPerView: 1.2,
                    },
                    768: {
                        slidesPerView: 2.4,
                        
                    },
                }}
                virtual
            >
                {imageNames.map((imageName, index) => (
                    <SwiperSlide key={index} virtualIndex={index}>
                        <div className="flex justify-center items-center">
                            <img
                                src={`/images/reviews/${imageName}`}
                                alt={`Review ${index + 1}`}
                                className="review-image h-[500px] w-auto object-contain rounded"
                            />
                        </div>
                    </SwiperSlide>
                ))}
                <div className="swiper-pagination"></div>
            </Swiper>
        </div>
    );
};

export default ReviewsSlider3;