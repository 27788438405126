import React from 'react';
import { FaBolt, FaSackDollar, FaPlane, FaBrain, FaChartSimple } from 'react-icons/fa6';
import useIntersectionObserver from './utility/useIntersectionObserver';

const FeatureItem = ({ icon, header, subheader }) => (
    <div className="flex flex-col items-center text-center">
        {icon}
        <div className="mt-4 text-white">
            <div className="font-bold">{header}</div>
            <div>{subheader}</div>
        </div>
    </div>
);

const FeatureSection = () => {
    const [ref, isIntersecting] = useIntersectionObserver({
        threshold: 0.1,
        triggerOnce: false
    });

    return (
        <div
            ref={ref}
            style={{
                background: 'linear-gradient(35deg, #2b2e39 0%, #818387 40%, #2b2e39 70%)',
                padding: '4rem 0'  // This is equivalent to py-16
            }}
        >
            <div className="container mx-auto px-8">
                <div
                    className={`bg-black shadow-lg p-8 relative ${isIntersecting ? 'animate-fadeIn' : 'opacity-0'
                        }`}
                >
                    <div className="absolute top-4 left-0 right-0 text-center">
                        <span className="text-[#c5e61e] text-sm font-bold px-4 py-2 bg-black bg-opacity-50 rounded">
                            START NOW
                        </span>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8 mt-8">
                        <FeatureItem
                            icon={<FaBolt className="text-5xl text-[#c5e61e]" />}
                            header="Manage Capital"
                            subheader="Learn Risk Management"
                        />
                        <FeatureItem
                            icon={<FaSackDollar className="text-5xl text-[#c5e61e]" />}
                            header="Build Wealth"
                            subheader="Power of Compounding"
                        />
                        <FeatureItem
                            icon={<FaPlane className="text-5xl text-[#c5e61e]" />}
                            header="Gain Freedom"
                            subheader="Financial and Geographical"
                        />
                        <FeatureItem
                            icon={<FaBrain className="text-5xl text-[#c5e61e]" />}
                            header="Discover Yourself"
                            subheader="Psychological Mastery"
                        />
                        <FeatureItem
                            icon={<FaChartSimple className="text-5xl text-[#c5e61e]" />}
                            header="Trade any Asset"
                            subheader="Forex, Stocks, Crypto, Indices"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeatureSection;