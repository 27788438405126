// useDisableInteractions.js

import { useEffect } from 'react';

const useDisableInteractions = () => {
    useEffect(() => {
        // Disable right-click
        const handleContextMenu = (e) => {
            e.preventDefault();
        };

        // Disable text selection
        const disableSelection = (e) => {
            e.preventDefault();
            return false;
        };

        // Apply CSS to disable text selection
        document.body.style.userSelect = 'none';
        document.body.style.webkitUserSelect = 'none';
        document.body.style.msUserSelect = 'none';
        document.body.style.mozUserSelect = 'none';

        // Add event listeners
        document.addEventListener("contextmenu", handleContextMenu);
        document.addEventListener("selectstart", disableSelection);

        // For older browsers
        document.onselectstart = disableSelection;

        // Cleanup function
        return () => {
            document.removeEventListener("contextmenu", handleContextMenu);
            document.removeEventListener("selectstart", disableSelection);
            document.onselectstart = null;

            // Remove CSS properties
            document.body.style.userSelect = '';
            document.body.style.webkitUserSelect = '';
            document.body.style.msUserSelect = '';
            document.body.style.mozUserSelect = '';
        };
    }, []);
};

export default useDisableInteractions;