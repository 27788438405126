import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';

const FacebookPixel = ({ pixelId }) => {
    useEffect(() => {
        ReactPixel.init(pixelId);
        ReactPixel.pageView();
        return () => {
            ReactPixel.init(null); // Cleanup
        };
    }, [pixelId]);

    // Expose the trackPurchase method
    const trackPurchase = (value, currency = 'GBP') => {
        ReactPixel.track('Purchase', {
            value: value,
            currency: currency,
        });
    };

    // Attach the trackPurchase method to the window object
    // so it can be accessed globally
    React.useEffect(() => {
        window.trackFacebookPurchase = trackPurchase;
        return () => {
            delete window.trackFacebookPurchase;
        };
    }, []);

    return null;
};

export default FacebookPixel;