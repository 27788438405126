import React, { useState, useEffect } from "react";
import { Helmet } from 'react-helmet';
import { Calendar, User } from "lucide-react";
import { useNavigate, useLocation } from "react-router-dom";
import { blogPosts } from './blogPosts';

const FormattedContent = ({ content }) => {
    const paragraphs = content.split('\n\n');
    return (
        <>
            {paragraphs.map((paragraph, index) => {
                if (paragraph.startsWith('Key Features') || paragraph.startsWith('Why') || paragraph.startsWith('Maximizing') || paragraph.startsWith('The FTMO Challenge') || paragraph.startsWith('Introducing')) {
                    const [title, ...items] = paragraph.split('\n');
                    return (
                        <div key={index} className="mb-4">
                            <h3 className="text-xl font-semibold mb-2">{title}</h3>
                            <ul className="list-disc list-inside">
                                {items.map((item, i) => (
                                    <li key={i}>{item.replace(/^\d+\.\s/, '')}</li>
                                ))}
                            </ul>
                        </div>
                    );
                } else {
                    return <p key={index} className="mb-2">{paragraph}</p>;
                }
            })}
        </>
    );
};

export default function Blog() {
    const [expandedPost, setExpandedPost] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const createUrlFriendlyTitle = (title) => {
        return title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
    };

    const BlogPost = ({ title, date, author, excerpt, content, isExpanded, onToggle }) => {
        const urlFriendlyTitle = createUrlFriendlyTitle(title);

        return (
            <article className={`bg-zinc-900 bg-opacity-100 shadow-lg border border-zinc-700 overflow-hidden ${isExpanded ? 'col-span-full' : ''}`}>
                <div
                    className="p-4 cursor-pointer"
                    onClick={() => onToggle(urlFriendlyTitle)}
                >
                    <h2 className="text-xl font-bold text-white mb-2">{title}</h2>
                    <div className="flex items-center space-x-2 text-xs mb-2">
                        <span className="inline-flex items-center bg-[#c5e61e] text-black px-2 py-1 rounded">
                            <Calendar className="w-3 h-3 mr-1" />{date}
                        </span>
                        <span className="inline-flex items-center bg-[#c5e61e] text-black px-2 py-1 rounded">
                            <User className="w-3 h-3 mr-1" />{author}
                        </span>
                    </div>
                    {!isExpanded && (
                        <p className="text-gray-300 mb-2 text-sm">{excerpt.slice(0, 150) + '...'}</p>
                    )}
                </div>
                {isExpanded && (
                    <div className="px-4 pb-4 mt-0 border-t border-zinc-700">
                        <div className="prose prose-invert max-w-none text-white text-sm mt-4">
                            <FormattedContent content={content} />
                        </div>
                    </div>
                )}
            </article>
        );
    };

    const currentPost = blogPosts.find(post => createUrlFriendlyTitle(post.title) === expandedPost);

    useEffect(() => {
        const postTitle = location.pathname.split('/blog/')[1];
        if (postTitle) {
            setExpandedPost(postTitle);
        } else {
            setExpandedPost(null);
        }
    }, [location]);

    const handleToggle = (urlFriendlyTitle) => {
        if (expandedPost === urlFriendlyTitle) {
            setExpandedPost(null);
            navigate('/blog', { replace: true });
        } else {
            setExpandedPost(urlFriendlyTitle);
            navigate(`/blog/${urlFriendlyTitle}`, { replace: true });
        }
    };

    return (
        <div className="px-7 sm:px-0 py-16">
            {currentPost ? (
                <Helmet>
                    <title>{`${currentPost.title} | Learn How to Trade Articles`}</title>
                    <meta name="description" content={String(currentPost.excerpt)} />
                    <meta property="og:title" content={String(currentPost.title)} />
                    <meta property="og:description" content={String(currentPost.excerpt)} />
                    <meta name="author" content={String(currentPost.author)} />
                    <meta name="keywords" content={currentPost.tags.join(', ')} />
                </Helmet>
            ) : (
                <Helmet>
                    <title>Learn How to Trade Forex</title>
                    <meta name="description" content="Stay updated with the latest trading insights, market analysis, and software development news for traders." />
                </Helmet>
            )}

            <div className="max-w-7xl mx-auto">
                <h1 className="text-3xl font-bold text-white mb-8 text-center">BLOG</h1>
                <meta name="description" content="Stay updated with the latest trading insights, market analysis, and software development news for traders." />

                <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
                    {blogPosts.map((post, index) => (
                        <BlogPost
                            key={index}
                            {...post}
                            isExpanded={expandedPost === createUrlFriendlyTitle(post.title)}
                            onToggle={handleToggle}
                        />
                    ))}
                </section>
            </div>
        </div>
    );
}